import OurModal from "@/components/Modal/index";
import style from "./index.module.less";
import Image from "next/image";
import React, { useState } from "react";
import { Input, Button, message } from "antd";
import { postAddManualClewApi } from "@/lib/service";
import useUserAge from "@/context/useUserage";
import { useTranslation } from "next-i18next";

interface Iprops {
  isAddLinkModal: boolean;
  handleClose: () => void;
  upDataList: () => void;
}
const Index: React.FC<Iprops> = ({
  isAddLinkModal,
  handleClose,
  upDataList,
}) => {
  const { t } = useTranslation("common");
  const [links, setLinks] = useState("");
  const [subLoading, setSubLoading] = useState(false);
  const { userUse, UpdateUserUs } = useUserAge();

  //   确认

  const handleConfirm = () => {
    if (!links) {
      message.warning(t("Please enter the infringing link"));
      return;
    }
    setSubLoading(true);
    postAddManualClewApi({ links: links.split("\n") }).then((res) => {
      if (res.code == 0) {
        setSubLoading(false);
        setLinks("");
        upDataList();
        handleClose();
        UpdateUserUs();
        message.success(res.message);
      } else {
        setSubLoading(false);
        message.warning(res.message);
      }
    });
  };
  return (
    <OurModal
      isModalOpen={isAddLinkModal}
      handleCancel={handleClose}
      width={350}
    >
      <div className={style.addLinkModal}>
        <div className={style.headIcon}>
          <Image
            src={"/images/erasa/safe-link/modal-top.webp"}
            height={46}
            width={46}
            alt="safe-link"
          ></Image>
        </div>
        <div className={style.title}>
          {t("Submitted Links")}
          {userUse?.request_num == -1
            ? t("Infinite")
            : `(${userUse?.used_request_num}/${userUse?.request_num})`}
        </div>
        <div className={style.desc}>
          {t("Add infringing links you’d like our team to remove for you.")}
        </div>
        <div className={style.main}>
          <Input.TextArea
            value={links}
            style={{ height: "8rem" }}
            placeholder={t(
              "Add links , Separate multiple links with a line break"
            )}
            onChange={(e) => setLinks(e.target.value)}
          ></Input.TextArea>
        </div>
        <div className={style.btns}>
          <Button
            onClick={() => {
              handleClose();
              setLinks("");
            }}
          >
            {t("Cancel")}
          </Button>
          <Button onClick={handleConfirm} loading={subLoading}>
            {t("Submit")}
          </Button>
        </div>
      </div>
    </OurModal>
  );
};
export default Index;
