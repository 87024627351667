import React, { useCallback, useEffect, useRef, useState } from "react";
// import { useRouter } from 'next/router';
import { GlobalHeader, GlobalFooter } from "@/components/Layouts";
import { useConfigProvider } from "@/context/ConfigProvider";
import classNames from "classnames";
// import SellOnPietraDialog from '../components/Layouts/SellOnPietraDialog';
import "./index.less";
import { AppSettings } from "@/shared/app-common";
import { useTranslation } from "next-i18next";
import { EventName } from "@/shared/event-types";
import { useRouter } from "next/router";
import { imgUrl } from "@/shared/utils";
import LeftFolder from "@/components/leftFolder/index";
// import ArticleBottomAlert from '@/components/Common/ArticleBottomAlert';
import { categoryList } from "@/lib/service/cms/api/collection/category";
import { GetServerSideProps } from "next";
import { Category, Page } from "@/lib/service/cms/type";

interface LayoutProps {
  /**
   * Please use next/head
   */
  head?: React.ReactNode;
  children: React.ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
}

const Layout = ({
  children,
  head,
  showHeader = true,
  showFooter = true,
}: LayoutProps) => {
  // const { pathname } = useRouter();
  const { isMobile, isBlogWeb } = useConfigProvider();
  // const [open, toggle] = useToggle(false);
  const { t } = useTranslation("common");
  // startData和其他样式区分
  const router = useRouter();
  const ref = useRef(null);

  const [categoryListData, setCategoryListData] = useState<Category[]>([]);
  // useEffect(() => {
  //   categoryList().then((res) => {
  //     setCategoryListData(res);
  //   });
  // }, []);
  const isShowHead = useCallback(() => {
    if (
      [
        "/dashboard",
        "/profile",
        "/safe-links",
        "/setting",
        "/leaks-found",
        "/protected-Accounts",
      ].includes(router.pathname) &&
      !isMobile
    ) {
      return false;
    } else {
      return true;
    }
  }, [router.pathname, isMobile]);

  const isShowBg = useCallback(() => {
    if (
      [
        "/dashboard",
        "/profile",
        "/safe-links",
        "/setting",
        "/dmca-protection-badge",
        "/leaks-found",
        "/protected-Accounts",
      ].indexOf(router.pathname) != -1 &&
      isMobile
    ) {
      return true;
    } else {
      return false;
    }
  }, [router.pathname, isMobile]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (ref.current) {
              // ref.current.style.backgroundImage = `url('/images/layouts/background-icon.png')`;
              // ref.current.style.marginTop = "62px";
              // if (!showHeader) {
              //   ref.current.style.marginTop = "0px";
              // }
            }
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [router.pathname.includes("starData"), showHeader]);
  // app sub title
  // const cmsMenus = categoryListData.map((category: Category) => {
  //   const text = category.name;
  //   const path = category.slug;
  //   const gaName = EventName.header_Home;
  //   const dropdownItems = category.pages?.map((page: Page) => {
  //     const pageText = page.name;
  //     const pagePath = page.slug;
  //     const gaName = EventName.header_Home;
  //     return {
  //       text: pageText,
  //       path: pagePath,
  //       gaName,
  //     };
  //   });
  //   return {
  //     text,
  //     path,
  //     gaName,
  //     dropdownItems,
  //   };
  // });
  const getNavItems = () => {
    switch (AppSettings.type) {
      case "16":
        return [
          // {
          //   text: t("home"),
          //   path: "/",
          //   // path: AppSettings.webHost,
          //   gaName: EventName.header_Home,
          // },
          {
            text: t("Service"),
            path: `/onlyfans-protection`,
            gaName: EventName.header_service,
            dropdownItems: [
              {
                text: t("OnlyFans Content Protection"),
                path: `/onlyfans-protection`,
                gaName: EventName.header_onlyfans_protection,
              },
              {
                text: t("Impersonation Account Removal"),
                path: `/remove-fake-account`,
                gaName: EventName.header_remove_fake_account,
              },
              {
                text: t("Cam Model Protection"),
                path: `/cam-model-protection`,
                gaName: EventName.header_cam_model_protection,
              },
            ],
          },
          {
            text: t("DMCA Badges"),
            path: "/dmca-protection-badge",
            // path: AppSettings.webHost,
            gaName: EventName.dmca_Home,
          },
          // ...cmsMenus,
          {
            text: t("Pricing"),
            path: "/plan",
            gaName: EventName.header_Plan,
          },
          {
            text: t("blog"),
            path: `/blog`,
            gaName: EventName.header_Blog,
          },
          {
            text: t("Free Tools"),
            path: `/onlyfans-caption-generator`,
            gaName: EventName.header_tool,
            dropdownItems: [
              {
                text: t("OnlyFans Restricted Words Checker"),
                path: `/onlyfans-restricted-words-checker`,
                gaName: EventName.header_tool,
              },
              {
                text: t("OnlyFans Caption Generator"),
                path: `/onlyfans-caption-generator`,
                gaName: EventName.header_tool,
              },
            ],
          },
        ];

      default:
        [{}];
    }
  };

  const isShowLeft = () => {
    let urlArr = [
      "/dashboard",
      "/leaks-found",
      "/safe-links",
      "/protected-Accounts",
      "/setting",
      "/profile",
    ];
    if (urlArr.indexOf(router.pathname) == -1) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div
      className={"layout"}
      style={{
        backgroundSize: router.pathname == "/" ? "contain" : "cover",
        backgroundImage: !isShowBg()
          ? `url(${imgUrl(
            "https://res-front.pumpsoul.com/prod-erasa/image/background-icon.png",
            "big"
          )})`
          : "",
      }}
    >
      {head}
      {showHeader && isShowHead() && (
        <header className={"header"}>
          <GlobalHeader isMobile={isMobile} navItems={[...getNavItems()]} />
        </header>
      )}
      <main
        ref={ref}
        // style={suitData()}
        style={{
          marginTop: !isShowHead()
            ? 0
            : "calc(var(--pietra-global-header-heigth))",
        }}
        className={classNames("main", {
          mobile: isMobile,
        })}
      >
        <div>
          {isShowLeft() && <LeftFolder></LeftFolder>}
          <div>{children}</div>
        </div>
      </main>
      {isShowHead() && (
        <footer className={"footer"}>
          <GlobalFooter />
        </footer>
      )}
    </div>
  );
};
export default Layout;
